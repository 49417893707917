// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-abc-expand-js": () => import("./../src/pages/ABCExpand.js" /* webpackChunkName: "component---src-pages-abc-expand-js" */),
  "component---src-pages-ciempies-expand-js": () => import("./../src/pages/CiempiesExpand.js" /* webpackChunkName: "component---src-pages-ciempies-expand-js" */),
  "component---src-pages-comida-expand-js": () => import("./../src/pages/ComidaExpand.js" /* webpackChunkName: "component---src-pages-comida-expand-js" */),
  "component---src-pages-contacto-js": () => import("./../src/pages/Contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-familia-expand-js": () => import("./../src/pages/FamiliaExpand.js" /* webpackChunkName: "component---src-pages-familia-expand-js" */),
  "component---src-pages-hora-expand-js": () => import("./../src/pages/HoraExpand.js" /* webpackChunkName: "component---src-pages-hora-expand-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-llano-expand-js": () => import("./../src/pages/LlanoExpand.js" /* webpackChunkName: "component---src-pages-llano-expand-js" */),
  "component---src-pages-nana-expand-js": () => import("./../src/pages/NanaExpand.js" /* webpackChunkName: "component---src-pages-nana-expand-js" */),
  "component---src-pages-pasteles-expand-js": () => import("./../src/pages/PastelesExpand.js" /* webpackChunkName: "component---src-pages-pasteles-expand-js" */),
  "component---src-pages-portfolio-js": () => import("./../src/pages/Portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-universo-expand-js": () => import("./../src/pages/UniversoExpand.js" /* webpackChunkName: "component---src-pages-universo-expand-js" */)
}

